<template>
	<div>
		<div class="row">
			<div class="col-xl-12">
				<b-card>
					<b-row>
						<b-col sm="4">
							<!-- Parameter -->
							<b-card no-body class="border mt-1">
								<b-card-header class="p-1">
									<b-card-title class="font-medium-1">
										<feather-icon icon="GlobeIcon" size="14" />
										<span class="align-middle ml-50">Manage Usage</span>
									</b-card-title>
								</b-card-header>
								<b-row class="pl-1 pr-1 ">
									<b-col>
										<b-form-group label-cols="4" label-cols-lg="6" label-size="sm" label="Usage Name" label-for="usage_name">
											<b-form-input id="usage_name" v-model="manage_usage_name" size="sm" placeholder="usage name" :disabled="status_entry == 'edit'"/>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row class="pl-1 pr-1 mt-1">
									<b-col>
										<b-form-group label-cols="4" label-cols-lg="6" label-size="sm" label="Usage Status" label-for="usage_status">
											<b-form-select id="usage_status" v-model="manage_usage_status" :options="manage_usage_status_opt" size="sm" />
										</b-form-group>
									</b-col>
								</b-row>
							</b-card>
							<!--  button car1 -->
							<b-button v-if="status_entry == 'insert'" variant="primary" @click="submit" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
								Create New
							</b-button>
							<b-button v-if="status_entry == 'edit'" variant="primary" @click="update" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
								Update
							</b-button>
							<b-button v-if="status_entry == 'edit'" variant="gradient-danger" @click="cancel"  class="mb-1 mb-sm-0 mr-0 mr-sm-1" type="reset" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
								Batal
							</b-button>
						</b-col>

						<b-col sm="8">
							<!-- Parameter TABLE -->
							<b-card no-body class="border mt-1 ">
								<b-card-header class="p-1">
									<b-card-title class="font-medium-1">
										<feather-icon icon="GlobeIcon" size="14" />
										<span class="align-middle ml-50">List Usages</span>
									</b-card-title>
								</b-card-header>
								<b-row class="pl-1 pr-1 mt-1 ml-1">
									<!-- Table 1 -->
									<div class="custom-search d-flex justify-content-end">
										<b-form-group>
											<div class="d-flex align-items-center">
												<label class="mr-1">Search</label>
												<b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" size="sm" />
											</div>
										</b-form-group>
									</div>
								</b-row>
								<div class="ml-1 mr-1"> 
									<!-- table -->
									<vue-good-table
										:columns="columns"
										:rows="rows"
										:rtl="direction"
										:search-options="{
											enabled: true,
											externalQuery: searchTerm,
										}"
										
										:pagination-options="{
											enabled: true,
											perPage: pageLength,
										}"
									>
										<template slot="table-row" slot-scope="props">
											
											<!-- Colum Status-->
											<span v-if="props.column.field === 'manage_usage_status'">
												<span v-if="props.row.manage_usage_status == true">
													active
												</span>
												<span v-else>
													inactive
												</span>
											</span>
											<!-- Column: Action -->
											<span v-else-if="props.column.field === 'action'">
												<span>
													<b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
													<template v-slot:button-content>
														<feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
													</template>
													<b-dropdown-item @click="onActionEdit(props.row)"> 
														<feather-icon icon="Edit2Icon" class="mr-50" />
														<span>Edit</span>
													</b-dropdown-item>
													<b-dropdown-item @click="onActionDelete(props.row.path)">
														<feather-icon icon="TrashIcon" class="mr-50" />
														<span>Delete</span>
													</b-dropdown-item>
													</b-dropdown>
												</span>
											</span>

											<!-- Column: Common -->
											<span v-else>
											{{ props.formattedRow[props.column.field] }}
											</span>
										</template>

										<!-- pagination -->
										<template slot="pagination-bottom" slot-scope="props">
											<div class="d-flex justify-content-between flex-wrap">
											<div class="d-flex align-items-center mb-0 mt-1">
												<span class="text-nowrap ">
												Showing 1 to
												</span>
												<b-form-select v-model="pageLength" :options="['10', '15', '25']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
												<span class="text-nowrap"> of {{ props.total }} entries </span>
											</div>
											<div>
												<b-pagination
												:value="1"
												:total-rows="props.total"
												:per-page="pageLength"
												first-number
												last-number
												align="right"
												prev-class="prev-item"
												next-class="next-item"
												class="mt-1 mb-0"
												@input="(value) => props.pageChanged({ currentPage: value })"
												>
												<template #prev-text>
													<feather-icon icon="ChevronLeftIcon" size="18" />
												</template>
												<template #next-text>
													<feather-icon icon="ChevronRightIcon" size="18" />
												</template>
												</b-pagination>
											</div>
											</div>
										</template>
									</vue-good-table>
								</div>
								<!-- end table 1 -->
								<!-- table 2 -->
								
							</b-card>	
							<!-- Action Buttons -->
						</b-col>
					</b-row>
				</b-card>
			</div>
		</div>
	</div>
</template>

<script>
import {
  BCard,
  VBTooltip,
  BCardHeader,
  BContainer,
  BRow,
  BCol,
  BFormSelect,
  BForm,
  BButton,
  BButtonGroup,
  BFormGroup,
  BCollapse,
  BFormDatepicker,
  BFormInput,
  BTable,
  BAvatar,
  BBadge,
  BPagination,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { TrashIcon, Edit2Icon } from "vue-feather-icons";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import axios from 'axios'
import metadata from "@/api/metadata";
import helper from '@/api/helper'
export default {
	components: {
		BCard,
		BCardCode,
		VueGoodTable,
		BCardHeader,
		BContainer,
		BRow,
		BCol,
		BFormSelect,
		BForm,
		BButton,
		BButtonGroup,
		BFormGroup,
		BCollapse,
		BFormDatepicker,
		BFormInput,
		BTable,
		TrashIcon,
		Edit2Icon,
		BAvatar,
		BBadge,
		BPagination,
		BDropdown,
		BDropdownItem,
	},
	data: function() {
		return {
			pageLength: 10,
			dir: false,
			columns: [
				{
					label: "ID",
					field: "manage_usage_id",
					thClass: "text-center",
					tdClass: "text-center"
				},
				{
					label: "Name",
					field: "manage_usage_name",
				},
				{
					label: "Status",
					field: "manage_usage_status",
					thClass: "text-center",
					tdClass: "text-center"
				},
				{
					label: "Action",
					field: "action",
					thClass: "text-center",
					tdClass: "text-center"
				},
			],
			rows: [],
			searchTerm: "",
			items: [],
			status_entry : "insert",
			arr_region :{},
			path : "",
			manage_usage_id: "",
			manage_usage_name: "",
			manage_usage_status: "",
			manage_usage_status_opt: [{ value: false, text: "Inactive" },{ value: true, text: "Active" }],
			
		}
	},
	computed: {
		
		direction() {
			if (store.state.appConfig.isRTL) {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.dir = true;
				return this.dir;
			}
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			this.dir = false;
			return this.dir;
		},
	},
	created() {
		// this.$http.get("/good-table/basic").then((res) => {
		//   this.rows = res.data;
		//   console.log("ada rows : ",this.rows)
		// });
		
	},
	directives: {
		"b-tooltip": VBTooltip,
		Ripple,
	},
	methods: {
		onActionEdit(row) {
			// console.log('onActionEdit', row)
			this.status_entry = "edit"
			this.manage_usage_id = row.manage_usage_id
			this.manage_usage_name = row.manage_usage_name
			this.manage_usage_status = row.manage_usage_status
			
		},

		async onActionDelete(val) {
			// console.log(val);
			// const res = await  axios.delete(helper.getBaseUrl()+val,helper.getConfig())
		},

		update() {

			let strName = this.manage_usage_name
			let isExist = false
			if (this.rows.length > 0) {
				this.rows.forEach(element => {
					if (element.manage_usage_name.toLowerCase() == strName.toLowerCase()) {
						isExist = true
						return;
					}
				});
			}

			if (isExist) {
				
				this.$swal({
							title: "Usage Exist",
							text: "Usage "+strName+" sudah ada",
							icon: "danger",
							customClass: {
							confirmButton: "btn btn-primary",
							},
							buttonsStyling: false,
						});
				return;
			}
			let postdata = {
				manage_usage_name: this.manage_usage_name,
				manage_usage_status: this.manage_usage_status
			}
			metadata
				.patchManageUsage(this.manage_usage_id,  postdata)
				.then((response) => {
					if (response.status == 201 || response.status == 200 || response.status == 204) {
						this.$swal({
							title: "Update Sukses",
							text: "Usage telah di update",
							icon: "success",
							customClass: {
								confirmButton: "btn btn-primary",
							},
							buttonsStyling: false,
						});

						this.rows.forEach(element => {
							if (element.manage_usage_id == this.manage_usage_id) {
								element.manage_usage_name = this.manage_usage_name
								element.manage_usage_status = this.manage_usage_status
								return;
							}
						});
						this.status_entry = "insert"
						this.manage_usage_status = ""
						this.manage_usage_name = ""
						this.manage_usage_id = ""
					}
				})
				.catch((error) => {
					this.$swal({
					title: "Input Error",
					text: "Terjadi Kesalahan",
					icon: "error",
					customClass: {
						confirmButton: "btn btn-primary",
					},
					buttonsStyling: false,
					});
				});

			
		},
		submit() {

			let strName = this.manage_usage_name
			let isExist = false
			if (this.rows.length > 0) {
				this.rows.forEach(element => {
					if (element.manage_usage_name.toLowerCase() == strName.toLowerCase()) {
						isExist = true
						return;
					}
				});
			}

			if (isExist) {
				
				this.$swal({
							title: "Usage Exist",
							text: "Usage "+strName+" sudah ada",
							icon: "danger",
							customClass: {
							confirmButton: "btn btn-primary",
							},
							buttonsStyling: false,
						});
				return;
			}

			const bodyData = {
				"@type": "ManageUsage",
				manage_usage_name: this.manage_usage_name,
				manage_usage_status: this.manage_usage_status,
			};
			
			metadata
				.newManageUsage(bodyData)
				.then((response) => {
					console.log('response: ', response)
					if (response.status == 201 || response.status == 200 || response.status == 204) {
						let data = response.data
						const row = {
							manage_usage_id: data['@name'],
							manage_usage_name: this.manage_usage_name,
							manage_usage_status: this.manage_usage_status,
							path: data['@id']
						}
						this.rows.push(row)

						this.$swal({
							title: "Input Sukses",
							text: "Usage telah di simpan",
							icon: "success",
							customClass: {
							confirmButton: "btn btn-primary",
							},
							buttonsStyling: false,
						});
						
					}
				})
				.catch((error) => {
					console.log('error: ', error)
					if (error.response) {
						this.$swal({
							title: "Input Error",
							text: "Terjadi Kesalahan",
							icon: "error",
							customClass: {
								confirmButton: "btn btn-primary",
							},
							buttonsStyling: false,
						});
					}
					
				});

			this.status_entry = "insert"
		},
		cancel() {
			this.status_entry = "insert"
			this.manage_usage_name = ""
			this.manage_usage_status = ""
		},
		async getListUsages() {
			this.rows = [];
			try {
				const { data } = await metadata.getListManageUsage();
				// console.log('getListUsages -data', data);

				for (var i = 0; i < data.items.length; i++) {
					const row = {
						manage_usage_id: data.items[i].manage_usage_id,
						manage_usage_name: data.items[i].manage_usage_name,
						manage_usage_status: data.items[i].manage_usage_status,
						path: data.items[i]['@id']
					}
					this.rows.push(row)
					
				}
			} catch (e) {
				console.log(e);
			}
		},
	},
	watch: {
		// regions(newValue, oldValue) {
		//   console.log("old : " + oldValue + " new : " + newValue);
		//   // this.listPropinsi(newValue)
		//   this.region_filter = newValue;
		// },
		// region_filter(value) {
		//   if(value == "all"){
		//     this.listAllPropinsi();
		//   }else if(value != ""){
		//     this.listPropinsi(value);
		//   }
		
		// },
		// regions_opt() {
		//   // console.log(regions_opt);
		// },
	},
	mounted() {
		this.getListUsages();
	},
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
